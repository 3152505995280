<template>
    <div>
        <h2 class="clarity-h2 blue">RESET PASSWORD</h2>
        <div v-if="!showResetMsg" class="resetBox">
            <p class="body-text clarity-margin-bottom-m">Please enter you registered email address into the box below and click '<span class="blue"><b>Reset Password</b></span>'. A reset link will then be sent to you.</p>
            <div>
                <div class="formgrid grid">
                    <div class="field col p-input-icon-left cl-input">
                        <i class="pi cl-email-icon" />
                        <InputText type="text" placeholder="Email address..." class="p-inputtext-lg w-full" v-model="email" @keyup.enter="passwordRecover" />
                    </div>
                </div>
                <br>
                <div class="flex flex-column sm:flex-row">
                    <div class="flex-1 justify-content-center" >
                        <router-link to="/login"><Button label="Back to Login" class="mr-2 mb-2 clarity-gradient-grey clarity-btn" style="width: 202px;" /></router-link>
                    </div>
                    <div class="flex-1 justify-content-center">
                        <Button @click="passwordRecover" label="Reset Password" class="mr-2 mb-2 clarity-gradient-orange clarity-btn" style="width: 290px;" />
                    </div>
                </div>
            </div>
            <hr class="clarity-margin-bottom-m clarity-margin-top-m">
            <p class="body-text dark-grey clarity-no-margin">Need help?</p>
            <p class="small-text grey">Find out more about "How to reset your Password".</p>
        </div>
        <div v-else class="flex flex-column clarity-margin-top-m">
            <div v-if="showConfirmationBox" id="sent-email-box" class="clarity-margin-bottom-s">
                <div class="flex justify-content-between">
                    <div class="flex">
                        <h3 class="clarity-h3"><span class="material-icons">error</span>EMAIL SENT!</h3>
                    </div>
                    <div class="flex grey cursor-pointer" @click="closeSentEmailMsg">
                        <span class="material-icons-outlined">close</span>
                    </div>
                </div>
                <p class="body-text dark-grey">If you have an account with us you should receive an email.</p>
                <p class="body-text dark-grey">Please check your inbox for the link to reset your password.</p>
            </div>
            <div v-if="showErrorBox" id="sent-email-error-box" class="clarity-margin-bottom-s">
                <div class="flex justify-content-between">
                    <div class="flex">
                        <h3 class="clarity-h3"><span class="material-icons">error</span>RESET ERROR</h3>
                    </div>
                    <div class="flex grey cursor-pointer" @click="closeErrorMessageBox">
                        <span class="material-icons-outlined">close</span>
                    </div>
                </div>
                <p class="body-text dark-grey">{{ errorMsg }}</p>
            </div>
            <div>
                <p class="body-text clarity-no-margin">Email not received?</p>
                <p class="small-text clarity-margin-bottom-s">If you didn’t receive the email, please click on the link below to request a new one.</p>
                <Button @click="resendRecover" label="Resend Email" class="clarity-gradient-grey clarity-btn" style="width: 202px;" />
            </div>
            <div class="hr-line"></div>
            <p class="body-text dark-grey clarity-no-margin">Need help?</p>
            <p class="small-text grey">Find out more about "How to reset your Password".</p>
        </div>
    </div>
</template>

<script>
    import { Auth } from '@aws-amplify/auth';

    export default {
        data () {
            return {
                email: '',
                errorMsg: '',
                showResetMsg: false,
                showConfirmationBox: false,
                showErrorBox: false
            }
        },
        methods: {
            async passwordRecover () {
                this.showErrorBox = false;
                if(this.email === '') {
                    this.errorMsg = 'No email address entered.';
                    this.showErrorBox = true;
                    this.showResetMsg = true;
                } else {
                    const isEmail = this.email.includes("@");
                    if(!isEmail) {
                        this.errorMsg = 'You must enter a email address.';
                        this.showErrorBox = true;
                        this.showResetMsg = true;
                    } else {
                        this.showResetMsg = false;
                        await Auth.forgotPassword(this.email.toLowerCase())
                            .then(data => {
                                console.log(data)
                            })
                            .catch(err => {
                                console.log(err)
                            })
                        this.showConfirmationBox = true;
                        this.showResetMsg = true;
                    }
                }  
            },
            async resendRecover() {
                //console.log('resend to:'+ this.email);
                await Auth.forgotPassword(this.email.toLowerCase())
                    .then(data => {
                        console.log(data)
                    })
                    .catch(err => {
                        console.log(err)
                    })
                if(!this.showConfirmationBox) {
                    this.showConfirmationBox = true;
                }
            },
            async closeSentEmailMsg() {
                this.showConfirmationBox = false;
            },
            async closeErrorMessageBox() {
                this.showConfirmationBox = false;
                this.showResetMsg = false;
            }
        }
    }
</script>

<style>
    .resetBox {
        width: 610px;
        margin: 45px auto;
    }
    .emailInputBox {
        border-radius: 30px;
        border: solid 1px #DCE1E6;
        height: 54px;
        width: 610px;
        font-size: 18px;
        background-color: white;
        background-image: url('../assets/icons/email-icon.png');
        background-position: 25px 15px;
        background-repeat: no-repeat;
        padding-left: 60px;
    }
    #sent-email-box {
        width: 610px;
        padding: 30px 20px 35px 30px;
        background: transparent linear-gradient(180deg, #1CD37F33 0%, #0195674D 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 5px 20px #0000000D;
        border: 1px solid #01956780;
        border-radius: 27px;
    }
    #sent-email-box p {
        margin-left: 35px;
        margin-bottom: 0px;
    }
    #sent-email-box h3 {
        color: var(--clarity-dark-green);
    }

    #sent-email-error-box {
        width: 610px;
        padding: 30px 20px 35px 30px;
        background: transparent linear-gradient(180deg, #F2686E33 0%, #D131384D 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 5px 20px #0000000D;
        border: 1px solid #D1313880;
        border-radius: 27px;
    }
    #sent-email-error-box p {
        margin-left: 35px;
        margin-bottom: 0px;
    }
    #sent-email-error-box h3 {
        color: var(--clarity-red);
    }

    .material-icons {
        vertical-align: top;
        margin-right: 10px !important;
    }
    .hr-line {
        margin: 45px 0px 45px 0px;
        border-bottom: 1px solid var(--clarity-dark-grey);
    }

    @media only screen and (max-width: 430px) {
        .resetBox {
            width: 400px;
        }
        #sent-email-box, #sent-email-error-box {
            width: 400px;
        }
    }

    @media only screen and (max-width: 425px) {
        .resetBox {
            width: 335px;
        }
        #sent-email-box, #sent-email-error-box {
            width: 335px;
        }
    }

    @media only screen and (max-width: 320px) {
        .resetBox {
            width: 310px;
        }
        #sent-email-box, #sent-email-error-box {
            width: 310px;
        }
    }
</style>
